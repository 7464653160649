// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "q_qY f_bS";
export var storyRowWrapper = "q_hL f_hL f_bY";
export var storyLeftWrapper = "q_qZ f_bP f_b2";
export var storyWrapperFull = "q_q0 f_cS";
export var storyWrapperFullLeft = "q_nQ f_cS f_bP f_b2 f_bS";
export var contentWrapper = "q_r f_hM";
export var storyLeftWrapperCenter = "q_q1 f_hN f_bP f_bS f_b2 f_bX";
export var storyRightWrapperCenter = "q_q2 f_hS";
export var storyHeader = "q_q3 f_hR f_L f_cH";
export var storyHeaderCenter = "q_hQ f_hQ f_L f_cH f_dL f_bN";
export var storyParagraphCenter = "q_hP f_hP f_bN f_dL";
export var storyBtnWrapper = "q_q4 f_n f_ff f_L f_bP f_bV";
export var storyBtnWrapperCenter = "q_hW f_hW f_ff f_L f_bP f_bS";
export var imageWrapper = "q_q5 f_ft f_bc";
export var imageWrapperFull = "q_q6 f_L f_W f_bt f_bc";