// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "p_qF f_gK f_cH";
export var heroHeaderCenter = "p_gL f_gL f_cH f_dL";
export var heroHeaderRight = "p_gM f_gM f_cH f_dM";
export var heroParagraphLeft = "p_qG f_gG f_cL";
export var heroParagraphCenter = "p_gH f_gH f_cL f_dL";
export var heroParagraphRight = "p_gJ f_gJ f_cL f_dM";
export var heroBtnWrapperLeft = "p_qH f_n f_ff f_L f_bP f_bV";
export var heroBtnWrapperCenter = "p_qJ f_fg f_ff f_L f_bP f_bS";
export var heroBtnWrapperRight = "p_qK f_fh f_ff f_L f_bP f_bW";
export var overlayBtnWrapper = "p_qL f_gF f_bd f_bm f_bn f_bp f_bC f_cr";
export var design4 = "p_qM f_gD f_bd f_bm f_bn f_bC";
export var heroExceptionSmall = "p_qN r_qN";
export var heroExceptionNormal = "p_qP r_qP";
export var heroExceptionLarge = "p_qQ r_qQ";
export var Title1Small = "p_qR r_qR r_q7 r_q8";
export var Title1Normal = "p_qS r_qS r_q7 r_q9";
export var Title1Large = "p_qT r_qT r_q7 r_rb";
export var BodySmall = "p_qV r_qV r_q7 r_rr";
export var BodyNormal = "p_qW r_qW r_q7 r_rs";
export var BodyLarge = "p_qX r_qX r_q7 r_rt";