// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "s_f2 f_f2 f_bP f_bW";
export var navbarDividedRight = "s_f3 f_f3 f_bP";
export var menuLeft = "s_sg f_f0 f_bP f_dL f_b2";
export var menuRight = "s_sh f_f0 f_bP f_dL f_b2";
export var menuCenter = "s_sj f_f1 f_f0 f_bP f_dL f_b2 f_L f_bS";
export var menuDivided = "s_n8 f_f1 f_f0 f_bP f_dL f_b2 f_L f_bS";
export var navbarItem = "s_n9 f_bM";
export var navbarLogoItemWrapper = "s_f9 f_f9 f_bR f_b2";
export var burgerToggle = "s_sk f_gq f_bN f_bH f_bc";
export var burgerToggleOpen = "s_sl f_gq f_bN f_bH f_bh";
export var burgerInput = "s_sm f_gf f_M f_bN f_bd f_cr f_dz f_bJ";
export var burgerLine = "s_sn f_gd f_bN f_bc f_M f_bB f_cD";
export var burgerMenuLeft = "s_sp f_gk f_gj f_gg f_gh f_bd f_cr f_dB f_bH f_dL";
export var burgerMenuRight = "s_sq f_gl f_gj f_gg f_gh f_bd f_cr f_dB f_bH f_dL";
export var burgerMenuCenter = "s_sr f_gm f_gj f_gg f_gh f_bd f_cr f_dB f_bH f_dL";
export var burgerMenuDivided = "s_ss f_gk f_gj f_gg f_gh f_bd f_cr f_dB f_bH f_dL";
export var btnWrapper = "s_n f_c9 f_c3 f_bP f_bW f_L";
export var cancelBtn = "s_st f_cf f_cS f_dQ";
export var icon = "s_p1";
export var secondary = "s_sv f_bR f_b2";