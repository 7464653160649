// extracted by mini-css-extract-plugin
export var articleWrapper = "m_pr";
export var articleText = "m_ps f_dK f_cH";
export var header = "m_pt f_bc f_L";
export var headerImageWrapper = "m_pv f_bs f_bd f_bn f_bm f_bj f_bp f_by f_b4";
export var headerGradient = "m_pw f_bs f_bd f_bn f_bm f_bj f_bp";
export var headerGradientOverlay = "m_px f_bs f_bd f_bn f_bm f_bj f_bp";
export var headerContentWrapper = "m_py f_bP f_b2 f_bS f_bX f_bz f_bc";
export var headerContentWrapperAlt = "m_pz f_bP f_b2 f_bS f_bX f_bz f_bc";
export var contentWrapper = "m_r f_L";
export var dateTag = "m_pB f_ct f_cG f_dF f_L";
export var icon1 = "m_pC f_c2";
export var icon2 = "m_pD f_c2 f_cX";
export var tagBtn = "m_pF f_cf f_cS f_dD f_dQ";
export var headerText = "m_pG f_L f_ct f_cH f_b8";
export var center = "m_pH f_dL";
export var videoIframeStyle = "m_pJ f_fj f_L f_W f_bN f_cf f_4";
export var articleImageWrapper = "m_pK f_cH f_bc";
export var articleImageWrapperIcon = "m_pL f_cH f_bc";
export var articleRow = "m_pM f_bS f_bt";
export var quoteWrapper = "m_g2 f_bR f_b2 f_cH";
export var quoteBar = "m_pN f_W";
export var quoteText = "m_pP";
export var authorBox = "m_pQ f_L";
export var authorRow = "m_pR f_bS f_b8 f_cN";
export var separator = "m_pS f_L";
export var line = "m_fx f_L f_fx f_db f_cN";
export var authorImage = "m_lH f_cm f_bc f_L f_cJ";
export var authorText = "m_pT f_cw f_cJ";
export var masonryImageWrapper = "m_pV";
export var bottomSeparator = "m_pW f_L f_cN";
export var linksWrapper = "m_pX f_cw f_dK";
export var comments = "m_pY f_bS f_cN";
export var sharing = "m_pZ f_bP f_bS f_bX";
export var shareText = "m_p0 f_L f_dL";
export var icon = "m_p1";
export var text = "m_p2";
export var SubtitleSmall = "m_p3";
export var SubtitleNormal = "m_p4";
export var SubtitleLarge = "m_p5";