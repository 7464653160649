// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "d_g";
export var warningBackgrounddark = "d_h";
export var warningBackgroundcustom = "d_j";
export var bottom = "d_k";
export var modal = "d_l";
export var container = "d_m";
export var btnWrapper = "d_n";
export var elementWrapper = "d_p";
export var titleWrapper = "d_q";
export var contentWrapper = "d_r";
export var btn = "d_s";
export var link = "d_t";
export var decline = "d_v d_s";
export var editor = "d_w";
export var row = "d_x";