// extracted by mini-css-extract-plugin
export var alignDiscLeft = "n_p6 f_fC f_bV f_dK";
export var alignLeft = "n_p7 f_fC f_bV f_dK";
export var alignDiscCenter = "n_p8 f_fD f_bS f_dL";
export var alignCenter = "n_b2 f_fD f_bS f_dL";
export var alignDiscRight = "n_p9 f_fF f_bW f_dM";
export var alignRight = "n_qb f_fF f_bW f_dM";
export var footerContainer = "n_qc f_d8 f_b8";
export var footerContainerFull = "n_qd f_d6 f_b8";
export var footerHeader = "n_ks f_ks";
export var footerTextWrapper = "n_qf f_L";
export var footerDisclaimerWrapper = "n_kz f_kz f_cy";
export var badgeWrapper = "n_qg f_L f_bP f_b2 f_bT f_bX";
export var footerDisclaimerRight = "n_kB f_kB f_bP";
export var footerDisclaimerLeft = "n_kC f_kC f_bP";
export var verticalTop = "n_qh f_bP f_bX f_b1 f_bZ";
export var firstWide = "n_qj";
export var disclaimer = "n_qk";
export var socialDisclaimer = "n_ql";
export var left = "n_qm";
export var wide = "n_qn f_cN";
export var right = "n_qp f_bY";
export var line = "n_fx f_fy f_cK";
export var badgeDisclaimer = "n_qq f_bR f_b2 f_bX";
export var badgeContainer = "n_qr f_bP f_bW f_b2";
export var badge = "n_qs";
export var padding = "n_qt f_dm";
export var end = "n_qv f_bW";
export var linkWrapper = "n_qw f_dQ";
export var link = "n_t f_dQ";
export var colWrapper = "n_qx f_cM";
export var media = "n_qy f_bM f_dN";
export var itemRight = "n_qz";
export var itemLeft = "n_qB";
export var itemCenter = "n_qC";
export var exceptionWeight = "n_qD r_rz";