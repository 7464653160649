// extracted by mini-css-extract-plugin
export var lbContainerOuter = "t_sw";
export var lbContainerInner = "t_sx";
export var movingForwards = "t_sy";
export var movingForwardsOther = "t_sz";
export var movingBackwards = "t_sB";
export var movingBackwardsOther = "t_sC";
export var lbImage = "t_sD";
export var lbOtherImage = "t_sF";
export var prevButton = "t_sG";
export var nextButton = "t_sH";
export var closing = "t_sJ";
export var appear = "t_sK";